





















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

import { LineSepa } from './lines';
import Pagination from '@/components/Pagination.vue';
import SkeletonLoader from '@/components/SkeletonLoader.vue';

import { InvoiceTotals } from '@/lib/services/invoices';
import { SepaCollection } from '@/models';
import { unwrapError } from '@/lib/helpers';
import { SepaService } from '@/lib/services';
import { Getter } from 'vuex-class';
import TotalsHeader from '@/components/archive/TotalsHeader.vue';

@Component({
  components: {
    TotalsHeader,
    LineSepa,
    SkeletonLoader,
    Pagination,
  },
})
export default class SepaCollections extends Vue {
  @Prop({ default: false })
  small: boolean;

  @Getter('settings/isViewOnly') viewOnly: boolean;

  entries: SepaCollection[] = [];
  loading: boolean = true;

  limit: number = 10;
  count: number = this.limit;
  totalEntries: number = 0;
  totalPages: number = 0;

  currentSort: string = 'number';
  currentReverse: boolean = true;
  totals: InvoiceTotals | null = null;

  // Debounced wrapper around loadPage
  updatePage = debounce((x: number = 1) => this.loadPage(x), 500);

  async mounted(): Promise<void> {
    const promises: Promise<any>[] = [];
    if (this.small) {
      this.limit = this.count = 5;
    } else {
      promises.push(this.updateTotals());
    }

    this.currentSort = 'id';

    promises.push(this.updatePage() as Promise<void>);
    await Promise.all(promises);
  }

  async loadPage(page = 1): Promise<void> {
    this.loading = true;
    try {
      const results = await SepaService.search(page, {
        order: this.currentSort,
        limit: +this.limit,
        reverse: this.currentReverse,
      });

      this.entries = results.items;

      this.totalEntries = results.total_items;
      this.totalPages = results.last;
      this.count = results.items.length;
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.load.sepa'), unwrapError(e));
    }

    this.loading = false;
  }

  async updatePageLimit(limit: number): Promise<void> {
    this.limit = limit;
    await this.updatePage(1);
  }

  sort(column: string): void {
    if (this.currentSort === column) {
      this.currentReverse = !this.currentReverse;
    } else {
      this.currentSort = column;
      this.currentReverse = true;
    }
    this.updatePage();
  }

  sortClasses(column: string): Record<string, boolean> {
    const active = this.currentSort === column;
    return {
      sortable: true,
      'sort-active': active,
      'sort-asc': active && !this.currentReverse,
      'sort-desc': active && this.currentReverse,
    };
  }

  @Watch('$route')
  async onRouteUpdate(): Promise<void> {
    if (this.$route.query['type'] !== 'sepa') {
      this.$emit('changetype', this.$route.query['type']);
      this.currentSort = 'id';
    }
    await Promise.all([
      this.updatePage(),
      this.updateTotals(),
    ] as Promise<any>[]);
  }

  async updateTotals(): Promise<void> {
    this.totals = await SepaService.searchTotals();
  }
}
