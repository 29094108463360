




























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { TabBar, Tab } from '@/components/tabbar';
import Fab from '@/components/Fab.vue';
import Invoices from '@/components/archive/Invoices.vue';
import SepaCollections from '@/components/archive/SepaCollections.vue';

import {
  INVOICE_SEARCH_TYPES,
  InvoiceSearchType,
} from '@/lib/services/invoices';
import {
  ARCHIVE_SEARCH_TYPES,
  ArchiveSearchType,
} from '@/components/archive/types';

import { ArchiveFilters } from '@/components/archive/types';

@Component({
  components: {
    TabBar,
    Tab,
    Invoices,
    SepaCollections,
    Fab,
  },
})
export default class ArchivePage extends Vue {
  invoiceSearchTypes: InvoiceSearchType[] = INVOICE_SEARCH_TYPES;
  type: ArchiveSearchType = 'invoice';
  filters: ArchiveFilters = new ArchiveFilters();

  tabOptions = [
    { type: 'invoice', label: 'archive.headers.invoices' },
    { type: 'quotation', label: 'archive.headers.quotations' },
    { type: 'recurring', label: 'archive.headers.recurring' },
    { type: 'draft', label: 'archive.headers.drafts' },
    { type: 'sepa', label: 'archive.headers.sepa' },
  ];

  $refs: {
    tabBar: TabBar;
  };

  created(): void {
    if (this.$route.query) {
      const type = this.$route.query['type'] as ArchiveSearchType;
      if (ARCHIVE_SEARCH_TYPES.includes(type)) {
        this.type = type;
      }

      for (const [k, v] of Object.entries(this.$route.query)) {
        if (this.filters.hasOwnProperty(k)) {
          this.filters[k] = v;
        }
      }
    }
  }

  newInvoice(): void {
    this.$router.push({
      name: 'invoice_create',
      query: { type: this.type },
    });
  }

  onTabSelect(idx: number): void {
    this.filters = new ArchiveFilters();
    this.type = this.tabOptions[idx].type as InvoiceSearchType;
    this.$router.replace({
      name: 'archive',
      query: {
        type: this.tabOptions[idx].type,
      },
    });
  }

  changeType(type: string): void {
    const idx = this.tabOptions.findIndex((e) => e.type === type);
    if (idx >= 0) {
      this.$refs.tabBar.select(idx, true);
    }
  }
}
